<template>
  <b-sidebar id="add-new-supplier-sidebar" :visible="isAddNewSupplierSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-supplier-sidebar-active', val)">
    <template #default="{ hide }">
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">{{supplierData.id?'修改':'新增'}}供應商資料</h5>
        <div>
          <feather-icon class="ml-1 cursor-pointer" icon="SaveIcon" size="18" style="color: green" @click="onSubmit" />
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
      </div>

      <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <validation-provider #default="validationContext" key="name" name="名稱" rules="required">
              <b-form-group class="required" label="名稱" label-for="name">
                <b-form-input v-model="supplierData.name" class="" placeholder="名稱"
                  :state="getValidationState(validationContext)" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
              <b-form-group class="" label="地址" label-for="address">
                <b-form-input v-model="supplierData.address" class="" placeholder="地址"
                  />
              </b-form-group>
            <validation-provider #default="validationContext" key="contacts" name="姓名" rules="required">
              <b-form-group class="required" label="姓名" label-for="contacts">
                <b-form-input v-model="supplierData.contacts" class="" placeholder="姓名"
                  :state="getValidationState(validationContext)" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group class="" label="電話" label-for="phone">
              <b-form-input v-model="supplierData.phone" class="" placeholder="電話"
                />
            </b-form-group>
            <validation-provider #default="validationContext" key="email" name="Email" rules="email">
            <b-form-group class="required" label="電郵" label-for="email">
              <b-form-input type="email" v-model="supplierData.email" class="" placeholder="電郵"
              :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
            <b-form-group class="" label="備註" label-for="remark">
              <b-form-textarea  v-model="supplierData.remark" class="" placeholder="備註"
                />
            </b-form-group>



            <b-col cols="12" md="12">
              <b-form-group label="狀態" label-for="is_active" label-cols-md="3">
                <b-form-checkbox :checked="supplierData.is_active" name="is_active" switch inline
                  v-model="supplierData.is_active" value="1">
                  {{ supplierData.is_active ? "啟用" : "禁用" }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>


            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                儲存
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                @click="hide">
                取消
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>
<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BOverlay,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BCol
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { useToast } from "vue-toastification/composition";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormTextarea,
    BOverlay,
    BFormDatepicker,
    quillEditor,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BCol
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewSupplierSidebarActive",
    event: "update:is-add-new-supplier-sidebar-active",
  },
  props: {
    isAddNewSupplierSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      field: ''
    };
  },
  methods: {
    hide() {
      //this.isAddNewSupplierSidebarActive = false;
      this.$emit('update:is-add-new-supplier-sidebar-active', false);
    },
    onSubmit() {
      this.$swal({
        title: "確定儲存？",
        text: "",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.$store
            .dispatch("supplier/addSupplier", this.supplierData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
              this.hide();
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  setup(props, { emit }) {
    const blankSupplierData = {
      id: 0,
          name: "",
          address: "",
          contacts: "",
          phone: "",
          email: "",
          remark: "",
          is_active: true
    };
    const loading = ref(false);

    const supplierData = ref(JSON.parse(JSON.stringify(blankSupplierData)));

    const resetsupplierData = () => {
      supplierData.value = JSON.parse(JSON.stringify(blankSupplierData));
    };

    watch(
      () => [props.id, props.isAddNewSupplierSidebarActive],
      () => {
        if (props.id > 0 && props.isAddNewSupplierSidebarActive) {
          supplierData.value = { ...props.data };
        } else {
        }
      }
    );

    const editorOption = {
      modules: {
        toolbar: "#quill-toolbar-supplier",
      },
      placeholder: "remark",
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetsupplierData);

    return {
      supplierData,
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      editorOption,
    };
  },
};
</script>

<style>
.ql-editor {
  height: 300px;
}
</style>
