import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSupplier(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/suppliers/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSuppliers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/suppliers", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addSupplier(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/suppliers", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteSupplier(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/suppliers", { params: data })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    restoreSupplier(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .patch(`/suppliers/${id}/restore`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    }
  },
};
